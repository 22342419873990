import React from "react";

const IconGlobe = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM16.9 6H14C13.7 4.7 13.2 3.6 12.6 2.4C14.4 3.1 16 4.3 16.9 6ZM10 2C10.8 3.2 11.5 4.5 11.9 6H8.1C8.5 4.6 9.2 3.2 10 2ZM2.3 12C2.1 11.4 2 10.7 2 10C2 9.3 2.1 8.6 2.3 8H5.7C5.6 8.7 5.6 9.3 5.6 10C5.6 10.7 5.7 11.3 5.7 12H2.3ZM3.1 14H6C6.3 15.3 6.8 16.4 7.4 17.6C5.6 16.9 4 15.7 3.1 14ZM6 6H3.1C4.1 4.3 5.6 3.1 7.4 2.4C6.8 3.6 6.3 4.7 6 6ZM10 18C9.2 16.8 8.5 15.5 8.1 14H11.9C11.5 15.4 10.8 16.8 10 18ZM12.3 12H7.7C7.6 11.3 7.5 10.7 7.5 10C7.5 9.3 7.6 8.7 7.7 8H12.4C12.5 8.7 12.6 9.3 12.6 10C12.6 10.7 12.4 11.3 12.3 12ZM12.6 17.6C13.2 16.5 13.7 15.3 14 14H16.9C16 15.7 14.4 16.9 12.6 17.6ZM14.4 12C14.5 11.3 14.5 10.7 14.5 10C14.5 9.3 14.4 8.7 14.4 8H17.8C18 8.6 18.1 9.3 18.1 10C18.1 10.7 18 11.4 17.8 12H14.4Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconGlobe;
