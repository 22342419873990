import React from "react";

const IconCamera = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 3.33203C13.5544 3.33203 13.7663 3.41983 13.9226 3.57611C14.0789 3.73239 14.1667 3.94435 14.1667 4.16536V7.66536L18.5109 4.6237C18.5733 4.57991 18.6466 4.55411 18.7227 4.54912C18.7989 4.54412 18.8749 4.56011 18.9426 4.59536C19.0102 4.63061 19.0669 4.68375 19.1064 4.74899C19.1459 4.81424 19.1668 4.88908 19.1667 4.96536V15.032C19.1668 15.1083 19.1459 15.1832 19.1064 15.2484C19.0669 15.3136 19.0102 15.3668 18.9426 15.402C18.8749 15.4373 18.7989 15.4533 18.7227 15.4483C18.6466 15.4433 18.5733 15.4175 18.5109 15.3737L14.1667 12.332V15.832C14.1667 16.053 14.0789 16.265 13.9226 16.4213C13.7663 16.5776 13.5544 16.6654 13.3334 16.6654H1.66671C1.44569 16.6654 1.23373 16.5776 1.07745 16.4213C0.921171 16.265 0.833374 16.053 0.833374 15.832V4.16536C0.833374 3.94435 0.921171 3.73239 1.07745 3.57611C1.23373 3.41983 1.44569 3.33203 1.66671 3.33203H13.3334ZM12.5 4.9987H2.50004V14.9987H12.5V4.9987ZM6.16671 7.3562C6.23013 7.35602 6.29228 7.37395 6.34587 7.40786L9.97504 9.71786C10.0222 9.74801 10.061 9.78953 10.0878 9.83861C10.1147 9.88769 10.1288 9.94274 10.1288 9.9987C10.1288 10.0547 10.1147 10.1097 10.0878 10.1588C10.061 10.2079 10.0222 10.2494 9.97504 10.2795L6.34587 12.5904C6.29533 12.6226 6.23702 12.6406 6.17711 12.6425C6.11719 12.6443 6.05788 12.63 6.00542 12.601C5.95296 12.572 5.90929 12.5294 5.87901 12.4777C5.84874 12.4259 5.83297 12.367 5.83337 12.307V7.69036C5.83337 7.5062 5.98337 7.35703 6.16671 7.35703V7.3562ZM17.5 7.36536L14.1667 9.6987V10.297L17.5 12.6304V7.36536Z"
        fill="#7E7E7E"
      />
    </svg>
  );
};

export default IconCamera;
