import React from "react";

const IconViewHide = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.882 19.2971C16.1232 20.4126 14.0828 21.0034 12 21.0001C6.60803 21.0001 2.12203 17.1201 1.18103 12.0001C1.61106 9.67078 2.78266 7.54296 4.52103 5.93407L1.39203 2.80807L2.80703 1.39307L22.606 21.1931L21.191 22.6071L17.881 19.2971H17.882ZM5.93503 7.35007C4.57604 8.58566 3.62935 10.2088 3.22303 12.0001C3.53532 13.3665 4.16229 14.6412 5.05403 15.7227C5.94577 16.8041 7.07766 17.6625 8.35958 18.2294C9.64151 18.7963 11.0381 19.0561 12.4381 18.9882C13.8382 18.9203 15.203 18.5264 16.424 17.8381L14.396 15.8101C13.5327 16.3539 12.5102 16.5882 11.4963 16.4745C10.4823 16.3608 9.53707 15.906 8.8156 15.1845C8.09413 14.463 7.63926 13.5178 7.52559 12.5038C7.41193 11.4899 7.64621 10.4674 8.19003 9.60407L5.93503 7.35007ZM12.914 14.3281L9.67203 11.0861C9.49409 11.539 9.45222 12.034 9.55154 12.5104C9.65085 12.9868 9.88705 13.4238 10.2311 13.7679C10.5752 14.1121 11.0123 14.3482 11.4887 14.4476C11.9651 14.5469 12.4601 14.505 12.913 14.3271L12.914 14.3281ZM20.807 16.5921L19.376 15.1621C20.0445 14.2094 20.5204 13.1353 20.777 12.0001C20.5053 10.8098 19.9943 9.68721 19.2752 8.70056C18.5561 7.71391 17.6438 6.88379 16.5939 6.26067C15.544 5.63755 14.3783 5.23443 13.1678 5.07583C11.9572 4.91723 10.727 5.00645 9.55203 5.33807L7.97403 3.76007C9.22103 3.27007 10.58 3.00007 12 3.00007C17.392 3.00007 21.878 6.88007 22.819 12.0001C22.5126 13.6658 21.8239 15.2376 20.807 16.5921ZM11.723 7.50807C12.3595 7.46873 12.9971 7.56513 13.5936 7.79088C14.19 8.01663 14.7316 8.36658 15.1826 8.81752C15.6335 9.26846 15.9835 9.81009 16.2092 10.4065C16.435 11.003 16.5314 11.6406 16.492 12.2771L11.722 7.50807H11.723Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconViewHide;
