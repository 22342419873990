import React from "react";

const IconPencil = ({ size, color = "black" }) => {
  return (
    <svg
      width={size ? size : "16"}
      height={size ? size : "16"}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4374 2.68921C17.0921 2.68921 17.7197 2.95063 18.1781 3.41263L21.279 6.51353C21.7407 6.97521 22.0001 7.60138 22.0001 8.25429C22.0001 8.9072 21.7407 9.53337 21.279 9.99505L9.95751 21.313C9.25902 22.1187 8.2689 22.6137 7.1346 22.6915H2V21.6915L2.00325 17.4765C2.08844 16.422 2.57867 15.4415 3.3265 14.7826L14.6954 3.41384C15.1564 2.95004 15.7834 2.68921 16.4374 2.68921ZM7.06398 20.694C7.59821 20.6562 8.09549 20.4076 8.49479 19.9508L16.0567 12.3889L12.3023 8.6344L4.6961 16.2388C4.29095 16.5971 4.04031 17.0984 4 17.557V20.6922L7.06398 20.694ZM13.7167 7.22036L17.4709 10.9747L19.8648 8.58083C19.9514 8.49423 20.0001 8.37677 20.0001 8.25429C20.0001 8.13181 19.9514 8.01435 19.8648 7.92775L16.7611 4.82407C16.6755 4.73775 16.5589 4.68921 16.4374 4.68921C16.3158 4.68921 16.1992 4.73775 16.1136 4.82407L13.7167 7.22036Z"
        fill={color}
      />
    </svg>
  );
};

export default IconPencil;
