const IconWhatNew = (props) => {
  return (
    <svg
      className="svg-color"
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.84125 12.8047L0.5 15.4297V1.55469C0.5 1.35578 0.579018 1.16501 0.71967 1.02436C0.860322 0.883705 1.05109 0.804688 1.25 0.804688H14.75C14.9489 0.804688 15.1397 0.883705 15.2803 1.02436C15.421 1.16501 15.5 1.35578 15.5 1.55469V12.0547C15.5 12.2536 15.421 12.4444 15.2803 12.585C15.1397 12.7257 14.9489 12.8047 14.75 12.8047H3.84125ZM3.32225 11.3047H14V2.30469H2V12.3434L3.32225 11.3047ZM4.25 6.05469H5.75C5.75 6.65142 5.98705 7.22372 6.40901 7.64568C6.83097 8.06764 7.40326 8.30469 8 8.30469C8.59674 8.30469 9.16903 8.06764 9.59099 7.64568C10.0129 7.22372 10.25 6.65142 10.25 6.05469H11.75C11.75 7.04925 11.3549 8.00308 10.6517 8.70634C9.94839 9.4096 8.99456 9.80469 8 9.80469C7.00544 9.80469 6.05161 9.4096 5.34835 8.70634C4.64509 8.00308 4.25 7.04925 4.25 6.05469Z"
        fill={props.color}
      />
    </svg>
  );
};

export default IconWhatNew;
