import React from "react";
import SidebarCollapse from "./SidebarCollapse/index";

import "./aside-style.scss";

function Sidebar() {
  return (
    <nav id="sidebar-fixed" className="">
      <SidebarCollapse />
    </nav>
  );
}

export default Sidebar;
