import React from "react";

const IconTAT = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9011 3.86139L15.3466 0L14.0595 1.48515L18.614 5.34654L19.9011 3.86139ZM5.9407 1.58416L4.65358 0.0990099L0.0991211 3.86139L1.38625 5.34654L5.9407 1.58416ZM10.4952 6.13861H9.01001V12.0792L13.6635 14.9505L14.4556 13.7623L10.4952 11.3861V6.13861ZM10.0001 2.17822C5.04961 2.17822 1.08922 6.13861 1.08922 11.0891C1.08922 16.0396 5.04961 20 10.0001 20C14.9506 20 18.911 16.0396 18.911 11.0891C18.911 6.13861 14.9506 2.17822 10.0001 2.17822ZM10.0001 18.0198C6.13872 18.0198 3.06942 14.9505 3.06942 11.0891C3.06942 7.22772 6.13872 4.15842 10.0001 4.15842C13.8615 4.15842 16.9308 7.22772 16.9308 11.0891C16.9308 14.9505 13.8615 18.0198 10.0001 18.0198Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconTAT;
