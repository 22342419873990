import React from "react";

const IconAdd = (props) => {
  return (
    <svg
      width={props.size ? props.size : "16"}
      height={props.size ? props.size : "16"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6.85714H6.85714V12H5.14286V6.85714H0V5.14286H5.14286V0H6.85714V5.14286H12V6.85714Z"
        fill={props.color ? props.color : "#545454"}
      />
    </svg>
  );
};

export default IconAdd;
