import { useEffect, useState } from "react";
import { checkWobotDomain } from "../../Services/checkWobotDomain";

const usePageTitle = (title) => {
  const [pageTitle, setPageTitle] = useState(title || "");
  useEffect(() => {
    document.title = checkWobotDomain(pageTitle);
  }, [pageTitle]);

  return { pageTitle, setPageTitle };
};

export default usePageTitle;
