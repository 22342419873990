import React from "react";

const IconLogout = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 15.2285C0.551088 15.2285 0.360322 15.1495 0.21967 15.0088C0.0790178 14.8682 0 14.6774 0 14.4785V0.978516C0 0.779603 0.0790178 0.588838 0.21967 0.448186C0.360322 0.307533 0.551088 0.228516 0.75 0.228516H11.25C11.4489 0.228516 11.6397 0.307533 11.7803 0.448186C11.921 0.588838 12 0.779603 12 0.978516V3.22852H10.5V1.72852H1.5V13.7285H10.5V12.2285H12V14.4785C12 14.6774 11.921 14.8682 11.7803 15.0088C11.6397 15.1495 11.4489 15.2285 11.25 15.2285H0.75ZM10.5 10.7285V8.47852H5.25V6.97852H10.5V4.72852L14.25 7.72852L10.5 10.7285Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconLogout;
