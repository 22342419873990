const colorMap = {
  openeye: "#000099",
  default: "#3766E8",
};

export const getRangeColorsByHost = (asString = true) => {
  const host = window?.location?.host;

  for (const key in colorMap) {
    if (host.includes(key)) {
      return asString ? colorMap[key] : [colorMap[key]];
    }
  }

  return asString ? colorMap.default : [colorMap.default];
};
