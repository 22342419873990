import React from "react";

const IconEvent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9999 2.00001H12.8149C12.4049 0.839999 11.3049 0 9.99987 0C8.69487 0 7.59486 0.839999 7.18489 2.00001H2.99989C1.89491 2.00001 0.999878 2.895 0.999878 4.00003V18C0.999878 19.105 1.89486 20 2.99989 20H16.9999C18.1049 20 18.9999 19.105 18.9999 18V3.99998C18.9999 2.895 18.1049 2.00001 16.9999 2.00001ZM9.99987 2.00001C10.5499 2.00001 10.9999 2.445 10.9999 3C10.9999 3.555 10.5499 3.99998 9.99987 3.99998C9.44989 3.99998 8.99989 3.555 8.99989 3C8.99989 2.445 9.44989 2.00001 9.99987 2.00001ZM7.9999 16L3.99988 12L5.41489 10.585L7.9999 13.17L14.5849 6.58499L15.9999 8.00001L7.9999 16Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconEvent;
