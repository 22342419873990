import React from "react";

const IconWarning = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z"
        fill="black"
      />
    </svg>
  );
};

export default IconWarning;
