import { useEffect, useState } from "react";

// Custom hook to detect tab focus/blur
function useTabFocus() {
  const [isTabActive, setIsTabActive] = useState(true);

  useEffect(() => {
    const handleFocus = () => {
      setIsTabActive(true);
    };

    const handleBlur = () => {
      setIsTabActive(false);
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  return isTabActive;
}

export default useTabFocus;
