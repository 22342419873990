import React from "react";

const IconSuccessToast = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 9C0.75 13.5563 4.44365 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9ZM11.4697 6.21967L7.5 10.1893L5.78033 8.46967L4.71967 9.53033L7.5 12.3107L12.5303 7.28033L11.4697 6.21967Z"
        fill="#029262"
      />
    </svg>
  );
};

export default IconSuccessToast;
