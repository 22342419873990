import React from "react";
import Skeleton from "react-loading-skeleton";

const HomeSkeleton = () => {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <div className="row mb-4">
          <div className="col-lg-6 col-md-6 col-sm-6 align-self-center">
            <div className="title">
              <h4>
                <Skeleton width={200} />
              </h4>
              <p className="mxw-300">
                <Skeleton count={2} />
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {Array(6)
            .fill()
            .map((e, index) => (
              <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
                <div className={`card-block`}>
                  <div className="title-block">
                    <div className={`icon-block mt-0 p-0`}>
                      <Skeleton height={40} width={40} />
                    </div>
                    <div className="content-block">
                      <h5 className="mb-1">
                        {" "}
                        <Skeleton height={20} width={200} />
                      </h5>
                      <p className="mb-0">
                        {" "}
                        <Skeleton height={10} width={250} />
                      </p>
                    </div>
                  </div>
                  <p>
                    {" "}
                    <Skeleton height={10} width={300} />
                  </p>
                  <div className={`bottom-block`}>
                    <span>
                      {" "}
                      <Skeleton height={20} width={100} />
                    </span>
                    <Skeleton height={20} width={100} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HomeSkeleton;
