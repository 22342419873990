import React from "react";

const IconSearch = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.6892C5.58172 18.6892 2 15.1075 2 10.6892C2 6.27093 5.58172 2.68921 10 2.68921C14.4183 2.68921 18 6.27093 18 10.6892C18 12.5379 17.3729 14.2402 16.3199 15.5949L21.7071 20.9821L20.2929 22.3963L14.9056 17.0091C13.551 18.0621 11.8487 18.6892 10 18.6892ZM16 10.6892C16 14.0029 13.3137 16.6892 10 16.6892C6.68629 16.6892 4 14.0029 4 10.6892C4 7.3755 6.68629 4.68921 10 4.68921C13.3137 4.68921 16 7.3755 16 10.6892Z"
        fill="black"
      />
    </svg>
  );
};

export default IconSearch;
