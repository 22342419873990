import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const Breadcrumb = (props) => {
  return (
    <ul className={"breadcrumb list-inline"}>
      {props.data.map((obj, index) => {
        return (
          <li key={obj.name} className={"list-inline-item "}>
            {/* Breadcrumb-sub-link */}
            {obj.link ? (
              <Link to={obj.link} className="breadcrum-link">
                {obj.name}
              </Link>
            ) : (
              <span className={"no-breadcrum-link"}>{obj.name}</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumb;
