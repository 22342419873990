import React from "react";
import callApi from "./callApi";
import { API_BASE_URL } from "../Constants";
import { showAlert } from "./showAlert";

const sendVerificationEmail = async (props) => {
  try {
    const res = await callApi(
      `${API_BASE_URL}/company/account/verification`,
      { method: "POST" },
      {
        showLoader: true,
        callManifest: false,
        loaderLabel: "",
      }
    );
    if (res?.status === 200) {
      showAlert(
        "Email sent successfully.\n" +
          "Check your spam folder in case you \n" +
          "don't see the email."
      );
    }
    // setConfirmationModalVisibility(false);
  } catch {
    showAlert("Something went wrong", "error");
    // setConfirmationModalVisibility(false);
  }
};

export default sendVerificationEmail;
