import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toggleLoaderAction } from "../../../../Store/actions";

const RouteChangeListener = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, userData } = useSelector((state) => state);

  useEffect(() => {
    const handleRouteChange = () => {
      if (loader?.value || (!userData && loader?.value)) {
        dispatch(
          toggleLoaderAction({
            label: "",
            value: false,
          })
        );
      }
    };

    const unListen = history.listen(handleRouteChange);

    return () => {
      unListen();
    };
  }, [dispatch, history, userData, loader]);

  return null;
};
export default RouteChangeListener;
