import React from "react";

function IconTimeFilter() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9406 15.4859L5.16505 0.138917C5.08322 0.0223453 4.93524 -0.0277118 4.79944 0.0151943C4.66363 0.0581004 4.57129 0.184076 4.57129 0.326541V15.6735C4.57129 15.8538 4.71748 16 4.89782 16H15.6733C15.7952 16 15.9069 15.9322 15.9631 15.8241C16.0193 15.716 16.0106 15.5855 15.9406 15.4859ZM5.22435 15.347V14.3674H6.20394V13.7143H5.22435V12.0816H6.20394V11.4286H5.22435V9.79593H6.20394V9.14287H5.22435V7.51022H6.20394V6.85715H5.22435V5.2245H6.20394V4.57144H5.22435V1.35985L15.0451 15.347H5.22435Z"
        fill="black"
      />
      <path
        d="M7.34704 13.0612H10.7756C10.8993 13.0612 11.0123 12.9914 11.0677 12.8807C11.123 12.7701 11.111 12.6377 11.0368 12.5388L7.60826 7.96735C7.52392 7.8549 7.37714 7.80902 7.24379 7.85349C7.11047 7.89793 7.02051 8.02273 7.02051 8.16327V12.7347C7.02051 12.915 7.1667 13.0612 7.34704 13.0612ZM7.67357 9.14286L10.1225 12.4082H7.67357V9.14286Z"
        fill="black"
      />
      <path
        d="M3.59184 0H0.326531C0.146188 0 0 0.146188 0 0.326531V15.6735C0 15.8538 0.146188 16 0.326531 16H3.59184C3.77218 16 3.91837 15.8538 3.91837 15.6735V0.326531C3.91837 0.146188 3.77218 0 3.59184 0ZM3.26531 15.3469H0.653061V13.8776H1.95918V13.2245H0.653061V11.2653H1.95918V10.6122H0.653061V8.32653H1.95918V7.67347H0.653061V5.38775H1.95918V4.73469H0.653061V2.77551H1.95918V2.12245H0.653061V0.653061H3.26531V15.3469Z"
        fill="black"
      />
    </svg>
  );
}

export default IconTimeFilter;
