import React from "react";

const IconCompany = () => {
  return (
    // <svg
    //   width="16"
    //   height="16"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M36 22.0506V13.9494L32.5957 13.2886C32.3443 12.5118 32.0304 11.7551 31.6576 11.0273L33.6021 8.15217L27.8795 2.41798L25.0007 4.35656C24.2646 3.97758 23.4984 3.65892 22.7114 3.40432L22.0506 0H13.9494L13.2886 3.40432C12.5118 3.65569 11.7549 3.96963 11.0273 4.34243L8.15217 2.39787L2.41798 8.12039L4.35656 10.9993C3.97758 11.7354 3.65892 12.5016 3.40432 13.2886L0 13.9494V22.0506L3.40432 22.7114C3.65569 23.4883 3.96963 24.245 4.34243 24.9728L2.39787 27.8479L8.12046 33.5821L10.9993 31.6435C11.7354 32.0225 12.5015 32.3411 13.2886 32.5957L13.9494 36H22.0506L22.7114 32.5957C23.4883 32.3443 24.245 32.0304 24.9727 31.6576L27.8479 33.6021L33.5821 27.8795L31.6434 25.0007C32.0224 24.2646 32.341 23.4985 32.5956 22.7114L36 22.0506ZM30.9261 20.8867L30.7521 21.5168C30.4611 22.5704 30.0385 23.5865 29.4958 24.5367L29.171 25.1056L30.8609 27.6151L27.5889 30.8804L25.0826 29.1853L24.5131 29.509C23.569 30.0455 22.5609 30.4638 21.5168 30.7521L20.8867 30.926L20.3113 33.8906H15.6887L15.1132 30.9261L14.4831 30.7521C13.4297 30.4612 12.4137 30.0386 11.4633 29.4958L10.8944 29.171L8.38491 30.8609L5.11959 27.5889L6.81469 25.0827L6.49104 24.5133C5.95448 23.5691 5.5362 22.561 5.24791 21.5168L5.07389 20.8867L2.10938 20.3113V15.6887L5.07389 15.1132L5.24791 14.4832C5.53873 13.4297 5.96145 12.4137 6.50412 11.4632L6.82889 10.8944L5.139 8.38491L8.41099 5.11952L10.9172 6.81462L11.4867 6.49097C12.4308 5.95441 13.439 5.53613 14.483 5.24784L15.1131 5.07389L15.6887 2.10938H20.3113L20.8868 5.07396L21.5169 5.24791C22.5703 5.5388 23.5864 5.96145 24.5368 6.50419L25.1056 6.82903L27.6152 5.13914L30.8805 8.41113L29.1854 10.9174L29.509 11.4868C30.0456 12.431 30.4638 13.4391 30.7522 14.4832L30.9262 15.1133L33.8906 15.6887V20.3113L30.9261 20.8867Z"
    //     fill="#FFC107"
    //   />
    //   <path
    //     d="M18.1464 6.82056C11.9813 6.82056 6.9657 11.8362 6.9657 18.0012C6.9657 24.1663 11.9813 29.1819 18.1464 29.1819C24.3114 29.1819 29.327 24.1663 29.327 18.0012C29.327 11.8362 24.3114 6.82056 18.1464 6.82056ZM18.1464 27.0725C13.1444 27.0725 9.07507 23.0032 9.07507 18.0012C9.07507 12.9993 13.1444 8.92993 18.1464 8.92993C23.1483 8.92993 27.2177 12.9993 27.2177 18.0012C27.2177 23.0032 23.1483 27.0725 18.1464 27.0725Z"
    //     fill="#FFC107"
    //   />
    //   <path
    //     d="M21.1135 16.9568C21.4758 16.4008 21.6873 15.738 21.6873 15.0263C21.6873 13.0706 20.0962 11.4795 18.1405 11.4795C16.1848 11.4795 14.5937 13.0706 14.5937 15.0263C14.5937 15.7399 14.8063 16.4045 15.1704 16.9613C13.5469 17.758 12.4263 19.4279 12.4263 21.3551V22.4098H23.8666V21.3551C23.8665 19.4244 22.7419 17.7518 21.1135 16.9568ZM18.1405 13.5888C18.9331 13.5888 19.578 14.2336 19.578 15.0262C19.578 15.8188 18.9331 16.4636 18.1405 16.4636C17.3479 16.4636 16.7031 15.8188 16.7031 15.0262C16.7031 14.2336 17.3479 13.5888 18.1405 13.5888ZM14.7432 20.3004C15.1595 19.2878 16.1567 18.573 17.3176 18.573H18.9751C20.136 18.573 21.1331 19.2878 21.5495 20.3004H14.7432Z"
    //     fill="#FFC107"
    //   />
    // </svg>

    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 7.44848V0.615234L13.75 5.11523V15.6152H0.25V5.11523L5.5 7.44848ZM7 3.14198V9.75698L1.75 7.42373V14.1152H12.25V6.00548L7 3.14273V3.14198Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconCompany;
