// Roles ["Owner" , "Admin" , "Executive" , "Supervisor" , "Account Manager"]
import { store } from "../Store";

class Roles {
  //get user role from redux store
  static getAccessList() {
    const { accessList } = store?.getState();
    return accessList || [];
  }

  //check if sidebar tab should be visible to role
  static authenticateTabs(tab) {
    if (tab.key) return this?.getAccessList()?.indexOf(tab.key) !== -1;
    else return true;
  }

  //check if action is allowed
  static authenticateViewAndAction(action) {
    return this.getAccessList().indexOf(action) !== -1;
  }

  //check if route is accessible to role
  static authenticateRoutes(routeName) {
    return true;
  }

  //check whether to show card to the role or not
  static authenticateCards(cardKey) {
    if (cardKey) return this?.getAccessList()?.indexOf(cardKey) !== -1;
    else return true;
  }
}

// class Roles {
//   //get user role from redux store
//   static getUserRole() {
//     const { userData } = store.getState();
//     return userData?.user?.role &&
//       userData.user.role.toLowerCase() in rolesConfig
//       ? userData.user.role
//       : "Owner";
//   }

//   //check if sidebar tab should be visible to role
//   static authenticateTabs(tabName) {
//     return (
//       rolesConfig[this.getUserRole().toLowerCase()].restrictedTabs.indexOf(
//         tabName
//       ) === -1
//     );
//   }

//   //check if route is accessible to role
//   static authenticateRoutes(routeName) {
//     let flag = true;
//     rolesConfig[this.getUserRole().toLowerCase()].restrictedRoutes.forEach(
//       (el) => {
//         if (routeName.indexOf(el) !== -1) flag = false;
//       }
//     );
//     return flag;
//   }

//   //check whether to show card to the role or not
//   static authenticateCards(cardTitle) {
//     return (
//       rolesConfig[this.getUserRole().toLowerCase()].restrictedCards.indexOf(
//         cardTitle
//       ) === -1
//     );
//   }
// }

export default Roles;
