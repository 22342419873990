const IconSiteStatus = (props) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 7.55469H1.75V14.3047H0.25V7.55469ZM12.25 4.55469H13.75V14.3047H12.25V4.55469ZM6.25 0.0546875H7.75V14.3047H6.25V0.0546875Z"
        fill={props.color}
      />
    </svg>
  );
};

export default IconSiteStatus;
