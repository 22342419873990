import React from "react";
import sendVerificationEmail from "../../../../Services/verifyEmail";
import "./style.scss";

const VerifyEmailStrip = (props) => {
  return (
    <>
      <div className={"verify-strip--wrapper"}>
        <div className={"block-wrapper--flex"}>
          {props.showNotifyContent && (
            <div className={"verify-content"}>
              <label>
                Check <span className={"role-text"}>{props.email}</span> for a
                link to verify your email and enable all Wobot AI features.{" "}
              </label>
              <p onClick={() => sendVerificationEmail()}>
                {" "}
                <u>Resend email</u>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VerifyEmailStrip;
